<template>
  <div>
    <v-sheet height="100vh" class="px-3">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <h1 class="primary--text text-center mt-15">Register</h1>
        </v-col>
        <v-col class="text-center" cols="12">
          <v-card outlined max-width="600" flat class="mx-auto px-2 pt-6">
            <v-form>
              <v-text-field
                v-model="dform.username"
                prepend-inner-icon="mdi-account-circle"
                label="Username"
                color="accent"
                outlined
                dense
              >
              </v-text-field>

              <v-text-field
                v-model="dform.password"
                prepend-inner-icon="mdi-lock"
                label="Password"
                outlined
                color="accent"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              >
              </v-text-field>

              <v-divider class="ma-2"></v-divider>
              <v-row justify="center" class="mt-3">
                <v-btn
                  color="accent"
                  :loading="loading"
                  class="mb-6"
                  @click="processRegister"
                >
                  Register
                </v-btn>
              </v-row>
            </v-form>
          </v-card>
          <h4 v-if="getMessages" class="success--text mt-7">
            {{ getMessages.successMessage }}
          </h4>
          <h4 v-if="getMessages" class="error--text mt-7">
            {{ getMessages.errorMessage }}
          </h4>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "RegisterPage",
  data() {
    return {
      loading: false,
      showPassword: false,
      dform: {
        username: "",
        password: "",
      },
    };
  },

  computed: {
    ...mapState(["admin"]),
    ...mapGetters(["getMessages"]),
  },

  methods: {
    ...mapActions(["setAdmin", "register"]),
    processRegister() {
      this.loading = true;
      this.register(this.dform);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
